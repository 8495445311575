import * as R from 'ramda'
import { v4 as uuid } from 'uuid'
import {
  Analytics,
  Builder as BuilderIcon,
  Chat,
  ForwardArrow as ForwardArrowIcon,
  Home,
  Hospital as HospitalIcon,
  Question,
  TeamMembers as TeamMembersIcon,
  AAHA as AAHAIcon,
} from '@pbt/pbt-ui-components/src/icons'
import GridViewSharpIcon from '@mui/icons-material/GridViewSharp'

import LeftMenuHelpButton from '@pbt/pbt-portal-ui/src/components/dashboard/menu/LeftMenuHelpButton'
import { useSelector } from 'react-redux'
import { getHasPIMSAccess, getCRUDByArea, getCurrentBusinessIsOmniChannel } from '@pbt/pbt-portal-ui/src/store/reducers/auth'
import { PermissionArea } from '@pbt/pbt-ui-components'

import { isBenchmarking } from '@pbt/pbt-portal-ui/src/utils'
import { getFeatureToggle } from '@pbt/pbt-portal-ui/src/store/reducers/constants'
import FeatureToggle from '@pbt/pbt-portal-ui/src/constants/featureToggle'
import { ReportingRouteConfig } from './reportingRouteConfig'
// @ts-ignore
import LeftMenuRhapsodyPimsButton from '../menu/LeftMenuRhapsodyPimsButton'
// @ts-ignore
import LeftMenuReportBuilderButton from '../menu/LeftMenuReportBuilderButton'
// @ts-ignore
import LeftMenuSupportButton from '../menu/LeftMenuSupportButton'
import { usePetabyteAnalyticsOnly } from '../../utils/usePetabyteAnalyticsOnly'
import { PetabyteAnalyticsOnlyReportingRouteConfig } from './petabyteAnalyticsOnlyReportingRouteConfig'
import { useAnalyticsTranslations } from '../../utils/useAnalyticsTranslations'
// @ts-ignore
import { BENCHMARK_PATH, BENCHMARK_PLUS_PATH } from '../routes/analyticsRoutes'
import LeftMenuAAHAButton from '../menu/LeftMenuAAHAButton'

const getMenu = (menuConfig: object) => R.filter(Boolean, Object.values(menuConfig))

export interface BenchmarkingAccess {
  benchmarkingOnlyAccess: boolean
  hasBenchmarkingAccess: boolean
  hasBenchmarkingLiteAccess: boolean
  hasBenchmarkingPlusAccess: boolean
}

export const useBenchmarkingState = (): BenchmarkingAccess => {
  const hasBenchmarkingLiteAccess = useSelector(getCRUDByArea(PermissionArea.BENCHMARKING_LIGHT))?.read
  const hasBenchmarkingPlusAccess = useSelector(getCRUDByArea(PermissionArea.BENCHMARKING_PLUS))?.read
  const hasAnalyticsReportsAccess = useSelector(getCRUDByArea(PermissionArea.ANALYTICS_REPORTS))?.read
  const hasBenchmarkingAccess = hasBenchmarkingLiteAccess || hasBenchmarkingPlusAccess
  const benchmarkingOnlyAccess = !hasAnalyticsReportsAccess && hasBenchmarkingAccess
  return {
    benchmarkingOnlyAccess, hasBenchmarkingAccess, hasBenchmarkingLiteAccess, hasBenchmarkingPlusAccess,
  }
}

export default () => {
  const hasPimsAccess = useSelector(getHasPIMSAccess)
  const isOmniChannel = useSelector(getCurrentBusinessIsOmniChannel)
  const isDisableBenchmarkingLight = useSelector(getFeatureToggle(FeatureToggle.DISABLE_BENCHMARKING_LITE_REPORTS))

  const { t } = useAnalyticsTranslations(['Benchmark', 'Dashboard'])

  const {
    benchmarkingOnlyAccess,
    hasBenchmarkingPlusAccess,
  } = useBenchmarkingState()
  const benchmarkingPermissionArea = hasBenchmarkingPlusAccess ?
    PermissionArea.BENCHMARKING_PLUS :
    PermissionArea.BENCHMARKING_LIGHT

  const petabyteAnalyticsOnly = usePetabyteAnalyticsOnly()

  const SPOTLIGHTS_PARAMS = {
    id: uuid(),
    permissionArea: benchmarkingPermissionArea,
    hideIfNoPermissions: true,
  }

  const SALES_REVENUE = {
    ...SPOTLIGHTS_PARAMS,
    id: uuid(),
    text: 'Sales revenue',
    url: '/reports/sales-revenue',
  }

  const PRODUCER_PERFORMANCE = {
    ...SPOTLIGHTS_PARAMS,
    id: uuid(),
    text: 'Producer performance',
    url: '/reports/producer-performance',
  }

  const OPPORTUNITIES = {
    ...SPOTLIGHTS_PARAMS,
    id: uuid(),
    text: 'Opportunities',
    url: '/reports/opportunities',
  }

  const TRENDS_IN_MEDICINE = {
    ...SPOTLIGHTS_PARAMS,
    id: uuid(),
    text: 'Trends in medicine',
    url: '/reports/trends-in-medicine',
  }

  const spotlightReports = [SALES_REVENUE, PRODUCER_PERFORMANCE, OPPORTUNITIES, TRENDS_IN_MEDICINE]
  if (hasBenchmarkingPlusAccess) {
    spotlightReports.forEach(a => { a.url += '-plus' })
  }

  const SPOTLIGHTS = {
    id: uuid(),
    text: 'SPOTlights',
    permissionArea: benchmarkingPermissionArea,
    hideIfNoPermissions: true,
    expanded: true,
    children: spotlightReports,
  }

  const fidoReports = [
    {
      id: uuid(),
      text: 'Fee Reference and Revenue Breakdown',
      hideIfNoPermissions: true,
      permissionArea: PermissionArea.BENCHMARKING_PLUS,
      url: '/reports/fido-fee-reference-and-revenue-breakdown',
    },
    {
      id: uuid(),
      text: 'Inventory',
      hideIfNoPermissions: true,
      permissionArea: PermissionArea.BENCHMARKING_PLUS,
      url: '/reports/fido-inventory',
    },
    {
      id: uuid(),
      text: 'Doctor Opportunities',
      hideIfNoPermissions: true,
      permissionArea: PermissionArea.BENCHMARKING_PLUS,
      url: '/reports/fido-doctor-opportunities',
    },
    {
      id: uuid(),
      text: 'Optimized Employee Health',
      hideIfNoPermissions: true,
      permissionArea: PermissionArea.BENCHMARKING_PLUS,
      url: '/reports/fido-optimized-employee-health',
    },
  ]

  const FIDO = {
    id: uuid(),
    text: 'FIDO',
    permissionArea: PermissionArea.BENCHMARKING_PLUS,
    hideIfNoPermissions: true,
    expanded: true,
    children: fidoReports,
  }

  const benchmarkingReportsOnly = {
    SPOTLIGHTS,
    FIDO,
  }

  const NEW_CLIENT_AND_PATIENT_ACTIVITY = {
    hideIfNoPermissions: true,
    id: uuid(),
    text: t('Dashboard:NEW_CLIENT_AND_PATIENT_ACTIVITY'),
    url: '/reports/new-client-and-patient-activity',
    disabled: petabyteAnalyticsOnly,
  }

  const PERFORMANCE_TRENDS = {
    hideIfNoPermissions: true,
    id: uuid(),
    text: t('Dashboard:PERFORMANCE_TRENDS'),
    url: '/reports/performance-trends',
  }

  const PERFORMANCE = {
    id: uuid(),
    text: t('Dashboard:PERFORMANCE_NEW'),
    permissionArea: PermissionArea.ANALYTICS_REPORTS,
    hideIfNoPermissions: true,
    expanded: true,
    children: [NEW_CLIENT_AND_PATIENT_ACTIVITY, PERFORMANCE_TRENDS],
  }

  const dailyReconciliation = {
    id: uuid(),
    text: 'Daily reconciliation',
    hideIfNoPermissions: true,
    permissionArea: PermissionArea.ANALYTICS_REPORTS,
    url: '/reports/daily-reconciliation-new',
  }
  const bankReconciliation = {
    id: uuid(),
    text: 'Bank reconciliation',
    hideIfNoPermissions: true,
    permissionArea: PermissionArea.ANALYTICS_REPORTS,
    url: '/reports/bank-reconciliation',
  }
  const invoicesAndPayments = {
    id: uuid(),
    text: 'Invoices and Payments',
    hideIfNoPermissions: true,
    permissionArea: PermissionArea.ANALYTICS_REPORTS,
    url: '/reports/invoices-and-payments',
  }
  const accounting = {
    id: uuid(),
    text: 'Accounting',
    hideIfNoPermissions: true,
    permissionArea: PermissionArea.ANALYTICS_REPORTS,
    url: '/reports/accounting',
  }

  const financialsChildren = [
    dailyReconciliation,
    bankReconciliation,
    invoicesAndPayments,
    accounting,
  ]

  const financialsAnalyticsOnlyChildren = [
    invoicesAndPayments,
    accounting,
    { ...dailyReconciliation, notActive: true },
    { ...bankReconciliation, notActive: true },
  ]

  const FINANCIALS_PARAMS = {
    id: uuid(),
    text: 'Financials (New)',
    permissionArea: PermissionArea.ANALYTICS_REPORTS,
    hideIfNoPermissions: true,
    expanded: true,
  }

  const FINANCIALS = {
    ...FINANCIALS_PARAMS,
    children: financialsChildren,
  }

  const FINANCIALS_ANALYTICS_ONLY = {
    ...FINANCIALS_PARAMS,
    children: financialsAnalyticsOnlyChildren,
  }

  const chewyVetCareReports = [
    {
      id: uuid(),
      text: 'Dental & Heartworm overview',
      hideIfNoPermissions: true,
      permissionArea: PermissionArea.ANALYTICS_REPORTS,
      url: '/reports/dental-and-heartworm-overview',
    },
    {
      id: uuid(),
      text: 'Appointment mix benchmark',
      hideIfNoPermissions: true,
      permissionArea: PermissionArea.ANALYTICS_REPORTS,
      url: '/reports/appointment-mix-benchmark',
    },
    {
      id: uuid(),
      text: 'Insured patients visits',
      hideIfNoPermissions: true,
      permissionArea: PermissionArea.ANALYTICS_REPORTS,
      url: '/reports/insured-patients-visits',
    },
  ]

  const CHEWY_VET_CARE_REPORTS = {
    id: uuid(),
    text: 'Chewy Vet Care',
    permissionArea: PermissionArea.ANALYTICS_REPORTS,
    hideIfNoPermissions: true,
    expanded: true,
    children: isOmniChannel ? chewyVetCareReports : [],
    disabled: !isOmniChannel,
    showDisabled: false,
  }

  const reportingConfig = {
    SPOTLIGHTS,
    FIDO,
    CHEWY_VET_CARE_REPORTS,
    FINANCIALS,
    PERFORMANCE,
    ACQUISITION_AND_LIFETIME_VALUE: ReportingRouteConfig.ACQUISITION_AND_LIFETIME_VALUE,
    CANCELLATIONS_AND_NO_SHOWS: ReportingRouteConfig.CANCELLATIONS_AND_NO_SHOWS,
    CLIENT_AND_PATIENT_STATUS: ReportingRouteConfig.CLIENT_AND_PATIENT_STATUS,
    CLIENT_LIST: ReportingRouteConfig.CLIENT_LIST,
    CONTROLLED_SUBSTANCE_LOG: ReportingRouteConfig.CONTROLLED_SUBSTANCE_LOG,
    DAILY_RECONCILIATION: ReportingRouteConfig.DAILY_RECONCILIATION,
    FORWARD_BOOKINGS: ReportingRouteConfig.FORWARD_BOOKINGS,
    GROUP_PERFORMANCE: ReportingRouteConfig.GROUP_PERFORMANCE,
    GROUP_PRICE_LISTS: ReportingRouteConfig.GROUP_PRICE_LISTS,
    INVENTORY_USAGE: ReportingRouteConfig.INVENTORY_USAGE,
    MAGIC_QUADRANT: ReportingRouteConfig.MAGIC_QUADRANT,
    MARKETING_DATA_QUALITY: ReportingRouteConfig.MARKETING_DATA_QUALITY,
    MEDICAL_INSIGHTS: ReportingRouteConfig.MEDICAL_INSIGHTS,
    MONTHLY_SALES_SUMMARY: ReportingRouteConfig.MONTHLY_SALES_SUMMARY,
    OPEN_SOAPS_AND_INVOICES: ReportingRouteConfig.OPEN_SOAPS_AND_INVOICES,
    PRACTICE_PERFORMANCE: ReportingRouteConfig.PRACTICE_PERFORMANCE,
    PRODUCTION: ReportingRouteConfig.PRODUCTION,
    RABIES_VACCINE_HISTORY: ReportingRouteConfig.RABIES_VACCINE_HISTORY,
    REFERRAL_BUSINESS: ReportingRouteConfig.REFERRAL_BUSINESS,
    REMINDERS_COMPLIANCE: ReportingRouteConfig.REMINDERS_COMPLIANCE,
    SCHEDULE_MANAGEMENT: ReportingRouteConfig.SCHEDULE_MANAGEMENT,
    STAFF_TIME_TRACKING: ReportingRouteConfig.STAFF_TIME_TRACKING,
    WELLNESS_PLANS: ReportingRouteConfig.WELLNESS_PLANS,
  }

  const petabyteAnalyticsOnlyReportingConfig = {
    SPOTLIGHTS,
    FIDO,
    FINANCIALS: FINANCIALS_ANALYTICS_ONLY,
    PERFORMANCE,
    CLIENT_AND_PATIENT_STATUS: PetabyteAnalyticsOnlyReportingRouteConfig.CLIENT_AND_PATIENT_STATUS,
    ACQUISITION_AND_LIFETIME_VALUE: PetabyteAnalyticsOnlyReportingRouteConfig.ACQUISITION_AND_LIFETIME_VALUE,
    DAILY_RECONCILIATION: PetabyteAnalyticsOnlyReportingRouteConfig.DAILY_RECONCILIATION,
    FORWARD_BOOKINGS: PetabyteAnalyticsOnlyReportingRouteConfig.FORWARD_BOOKINGS,
    GROUP_PERFORMANCE: PetabyteAnalyticsOnlyReportingRouteConfig.GROUP_PERFORMANCE,
    INVENTORY_USAGE: PetabyteAnalyticsOnlyReportingRouteConfig.INVENTORY_USAGE,
    MAGIC_QUADRANT: PetabyteAnalyticsOnlyReportingRouteConfig.MAGIC_QUADRANT,
    MONTHLY_SALES_SUMMARY: PetabyteAnalyticsOnlyReportingRouteConfig.MONTHLY_SALES_SUMMARY,
    PRACTICE_PERFORMANCE: PetabyteAnalyticsOnlyReportingRouteConfig.PRACTICE_PERFORMANCE,
    PRODUCTION: PetabyteAnalyticsOnlyReportingRouteConfig.PRODUCTION,
    REMINDERS_COMPLIANCE: PetabyteAnalyticsOnlyReportingRouteConfig.REMINDERS_COMPLIANCE,
    SCHEDULE_MANAGEMENT: PetabyteAnalyticsOnlyReportingRouteConfig.SCHEDULE_MANAGEMENT,
    CHEWY_VET_CARE_REPORTS,
  }

  const allReportsChildren = benchmarkingOnlyAccess
    ? getMenu(benchmarkingReportsOnly)
    : petabyteAnalyticsOnly
      ? getMenu(petabyteAnalyticsOnlyReportingConfig)
      : getMenu(reportingConfig)

  const AAHAItems = isBenchmarking ? [] : [{
    id: uuid(),
    text: 'AAHA',
    icon: AAHAIcon,
    Component: LeftMenuAAHAButton,
  }]

  return [
    {
      id: uuid(),
      text: 'Home',
      icon: Home,
      hideIfNoPermissions: true,
      url: '/dashboard',
      disabled: benchmarkingOnlyAccess,
    },
    {
      id: uuid(),
      text: t('Benchmark:BENCHMARKING'),
      icon: GridViewSharpIcon,
      permissionArea: hasBenchmarkingPlusAccess ? PermissionArea.BENCHMARKING_PLUS : PermissionArea.BENCHMARKING_LIGHT,
      hideIfNoPermissions: true,
      url: hasBenchmarkingPlusAccess ? BENCHMARK_PLUS_PATH : BENCHMARK_PATH,
    },
    {
      id: uuid(),
      text: 'All reports',
      icon: Analytics,
      permissionArea: benchmarkingOnlyAccess ? benchmarkingPermissionArea : PermissionArea.ANALYTICS_REPORTS,
      hideIfNoPermissions: true,
      doNotRenderChildren: true,
      children: allReportsChildren,
    },
    {
      id: uuid(),
      text: 'My reports',
      icon: BuilderIcon,
      permissionArea: PermissionArea.SELF_SERVE_PORTAL,
      hideIfNoPermissions: true,
      url: '/report-builder',
    },
    {
      id: uuid(),
      text: 'Go to Report Builder',
      icon: ForwardArrowIcon,
      permissionArea: PermissionArea.SELF_SERVE_PORTAL,
      hideIfNoPermissions: true,
      Component: LeftMenuReportBuilderButton,
    },
    {
      id: uuid(),
      text: 'Practices',
      icon: HospitalIcon,
      permissionArea: PermissionArea.PRACTICES,
      hideIfNoPermissions: true,
      url: '/practices',
    },
    {
      id: uuid(),
      text: 'Team members',
      icon: TeamMembersIcon,
      permissionArea: PermissionArea.PERSON,
      hideIfNoPermissions: true,
      url: '/members',
    },
    {
      id: uuid(),
      text: 'Tutorials and FAQs',
      icon: Question,
      Component: LeftMenuSupportButton,
    },
    {
      id: uuid(),
      text: 'Chat with support',
      icon: Chat,
      Component: LeftMenuHelpButton,
    },
    {
      id: uuid(),
      text: 'Go to rhapsody PIMs',
      icon: ForwardArrowIcon,
      ...(hasPimsAccess && { Component: LeftMenuRhapsodyPimsButton }),
    },
    ...AAHAItems,
  ].filter(({ permissionArea }) => (!isDisableBenchmarkingLight || permissionArea !== PermissionArea.BENCHMARKING_LIGHT))
}
