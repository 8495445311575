import React from 'react'
import { Grid, Fab } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { Text, PuiDialog } from '@pbt/pbt-ui-components'
import { useAnalyticsTranslations } from '../../utils/useAnalyticsTranslations'

const useStyles = makeStyles(theme => ({
  root: {
    width: 650,
    maxWidth: 650,
  },
  title: {
    color: theme.colors.markerHighlighted,
  },
  accessScoreBtn: {
    backgroundColor: theme.colors.markerHighlighted,
    minWidth: 138,
    height: 40,
    borderRadius: 129,
  },
  link: {
    textDecoration: 'none',
  },
  breakSpaces: {
    whiteSpace: 'break-spaces',
  },
}), { name: 'LevelUpDialog' })

interface LevelUpDialogProps {
  benchmarkingPlus: boolean
  getInTouchLink: string
  onClosed: (event?: any) => void
  opened: boolean
}

const LevelUpDialog = (props: LevelUpDialogProps) => {
  const classes = useStyles()
  const { benchmarkingPlus, getInTouchLink, onClosed, opened } = props
  const { t } = useAnalyticsTranslations('Benchmark')
  const titles = benchmarkingPlus ?
    {
      heading: t('Benchmark:LEVEL_UP_TO_UNLOCK_ANALYTICS'),
      first: t('Benchmark:GET_A_BIRDS_EYE'),
      second: t('Benchmark:ACCESS_DETAILED_DASHBOARDS'),
      third: t('Benchmark:TAKE_ACTION_INTERNALLY'),
      fourth: t('Benchmark:READY_TO_UPGRADE'),
    } :
    {
      heading: t('Benchmark:LEVEL_UP_TO_UNLOCK'),
      first: t('Benchmark:COMPARE_YOUR_METRICS'),
      second: t('Benchmark:ACCESS_MORE_DASHBOARDS'),
      third: t('Benchmark:GROW_PRACTICE'),
      fourth: t('Benchmark:READY_TO_CONNECT'),
    }
  const height = benchmarkingPlus ? 303 : 259
  return (
    <PuiDialog classes={{ paper: classes.root }} open={opened} onClose={onClosed}>
      <Grid
        container
        alignItems="center"
        className={classes.root}
        direction="column"
        height={height}
        justifyContent="space-around"
        p={3}
        width={650}
      >
        <Text className={classes.title} variant="hero3">{titles.heading}</Text>
        <Grid
          container
          alignItems="center"
          className={classes.breakSpaces}
          direction="column"
          flexGrow={1}
          justifyContent="space-between"
          mt={1}
        >
          <Text align="center" variant="body">{titles.first}</Text>
          <Text align="center" variant="body">{titles.second}</Text>
          <Text align="center" variant="body">{titles.third}</Text>
          <Text align="center" fontWeight={700} variant="body">{titles.fourth}</Text>
        </Grid>
        <Grid item mt={2}>
          <a className={classes.link} href={getInTouchLink} rel="noreferrer" target="_blank">
            <Fab className={classes.accessScoreBtn} color="inherit" variant="extended">
              {t('Benchmark:GET_IN_TOUCH')}
            </Fab>
          </a>
        </Grid>
      </Grid>
    </PuiDialog>
  )
}

export default LevelUpDialog
