import React from 'react'
import classNames from 'classnames'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { ClassesType, TextWithTooltip } from '@pbt/pbt-ui-components'

const useStyles = makeStyles(theme => ({
  root: {
    border: theme.constants.tableBorder,
    backgroundColor: theme.colors.tableBackground,
    minHeight: 248,
  },
  sectionHeading: {
    padding: theme.spacing(2),
    borderBottom: theme.constants.tableBorder,
  },
  icon: {
    color: theme.colors.title,
    marginRight: theme.spacing(1),
    width: 28,
    height: 28,
  },
  content: {
    padding: theme.spacing(2),
  },
  disabled: {
    opacity: 0.5,
    pointerEvents: 'none',
  },
  title: {},
}), { name: 'DashboardSection' })

interface DashboardSectionProps {
  Icon: React.JSXElementConstructor<any>
  children: React.ReactNode | React.ReactNode[]
  classes?: ClassesType<typeof useStyles>
  disabled: boolean
  hint?: React.ReactNode
  title: string
}

const DashboardSection = ({
  classes: classesProp,
  children,
  disabled,
  hint,
  Icon,
  title,
}: DashboardSectionProps) => {
  const classes = useStyles({ classes: classesProp })

  return (
    <Grid container
      className={classNames(classes.root, {
        [classes.disabled]: disabled,
      })}
      direction="column"
    >
      <Grid container item
        alignItems="center"
        className={classes.sectionHeading}
        wrap="nowrap"
      >
        <Icon className={classes.icon} />
        <Grid item className={classes.title}>
          {
            hint
              ? (
                <TextWithTooltip
                  strong
                  tooltipText={hint}
                  variant="h1"
                >
                  {title}
                </TextWithTooltip>
              )
              : title
          }
        </Grid>
      </Grid>
      <Grid item className={classes.content}>
        {children}
      </Grid>
    </Grid>
  )
}

export default DashboardSection
