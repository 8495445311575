import React from 'react'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { ClassesType, TextWithTooltip } from '@pbt/pbt-ui-components'
import { ForwardArrow as ForwardArrowIcon } from '@pbt/pbt-ui-components/src/icons'

import Link from '../../common/Link'

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(0, 1),
    marginBottom: theme.spacing(2),
  },
  link: {
    fontSize: '1.6rem',
    color: theme.colors.secondaryText,
    textDecoration: 'underline',
    marginLeft: theme.spacing(1),
  },
}), { name: 'DashboardLink' })

interface DashboardLinkProps {
  classes?: ClassesType<typeof useStyles>
  hint?: string
  text: string
  url: string
}

const DashboardLink = ({
  classes: classesProp,
  text,
  url,
  hint,
}: DashboardLinkProps) => {
  const classes = useStyles({ classes: classesProp })

  return (
    <Grid container
      alignItems="center"
      className={classes.root}
      wrap="nowrap"
    >
      <ForwardArrowIcon />
      <Link
        className={classes.link}
        to={url}
      >
        {
          hint
            ? (
              <TextWithTooltip tooltipText={hint}>
                {text}
              </TextWithTooltip>
            )
            : text
        }
      </Link>
    </Grid>
  )
}

export default DashboardLink
