import React, { useEffect } from 'react'
import { Routes, Route, Navigate, useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import makeStyles from '@mui/styles/makeStyles'
import Browser from 'bowser'
import { useAuth0, withAuthenticationRequired } from '@auth0/auth0-react'

import { CircularProgressOverlay } from '@pbt/pbt-ui-components'
import {
  getAccessToken,
  getAuthErrorType,
  getCurrentBusinessId,
} from '@pbt/pbt-portal-ui/src/store/reducers/auth'
import { useRedirectTo } from '@pbt/pbt-portal-ui/src/utils/useRedirectTo'
import { auth0Enabled } from '@pbt/pbt-portal-ui/src/utils'
import { FETCH_CURRENT_USER_FAILURE } from '@pbt/pbt-portal-ui/src/store/actions/types/auth'
import useLogout from '@pbt/pbt-portal-ui/src/utils/useLogout'
import { getHasClosedClientsSplashScreen } from '@pbt/pbt-portal-ui/src/store/reducers/journal'
import recommendedBrowsers from '@pbt/pbt-portal-ui/src/constants/recommendedBrowsers'
import { getShouldShowBrowserNotice } from '@pbt/pbt-portal-ui/src/store/reducers/loginSessionSettings'
import { getErrorsList } from '@pbt/pbt-portal-ui/src/store/duck/errors'
import { showRecommendedBrowsersDialog } from '@pbt/pbt-portal-ui/src/store/actions/loginSessionSettings'
import useDialog from '@pbt/pbt-portal-ui/src/utils/useDialog'
import DialogNames from '@pbt/pbt-portal-ui/src/constants/DialogNames'
import { USER_DEACTIVATED_MESSAGE } from '@pbt/pbt-portal-ui/src/constants/errorMessages'
import ErrorsBoundary from '@pbt/pbt-portal-ui/src/components/error/ErrorsBoundary'
import AlertsPool from '@pbt/pbt-portal-ui/src/components/dashboard/AlertsPool'
import CurrentUserFetcher from '@pbt/pbt-portal-ui/src/components/dashboard/CurrentUserFetcher'
import ForceLogOutWatcher from '@pbt/pbt-portal-ui/src/components/dashboard/watchers/ForceLogOutWatcher'
import BusinessWatcher from '@pbt/pbt-portal-ui/src/components/dashboard/watchers/BusinessWatcher'
import IntercomWidget from '@pbt/pbt-portal-ui/src/components/dashboard/IntercomWidget'
import Progress from '@pbt/pbt-portal-ui/src/components/dashboard/Progress'
import ProtectedRoute from '@pbt/pbt-portal-ui/src/components/dashboard/routes/ProtectedRoute'
import { renderMultiPathRoute } from '@pbt/pbt-portal-ui/src/components/dashboard/routes/utils'
import SwitchBrowserSplashScreen from '@pbt/pbt-portal-ui/src/components/dashboard/clients/SwitchBrowserSplashScreen'

import AnalyticsNavigatorWrapper from './AnalyticsNavigatorWrapper'
import { useGetAnalyticsRoutes, BENCHMARK_PATH, BENCHMARK_PLUS_PATH } from '../routes/analyticsRoutes'
import { GENERIC_ROUTES } from '../routes/genericRoutes'
import { useBenchmarkingState } from './useAnalyticsMenuConfig'

const useStyles = makeStyles(theme => ({
  content: {
    minHeight: '100vh',
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    backgroundColor: theme.colors.contentBackground,
    [theme.breakpoints.down('md')]: {
      minHeight: `calc(100vh - ${theme.mixins.toolbar.minHeight}px - ${theme.constants.progressBarHeight}px)`,
    },
  },
  toolbar: {
    display: 'flex',
    padding: theme.spacing(0, 1),
    [theme.breakpoints.up('md')]: {
      ...theme.mixins.toolbar,
    },
  },
}), { name: 'AnalyticsPortal' })

const getBrowserName = () => {
  try {
    const browser = Browser.getParser(window.navigator.userAgent)
    return browser.getBrowserName().toLowerCase()
  } catch (error) {
    // ignore errors
    return ''
  }
}

const AnalyticsPortal = () => {
  const { pathname = '' } = useLocation()
  const classes = useStyles()
  const dispatch = useDispatch()
  const errorType = useSelector(getAuthErrorType)
  const accessToken = useSelector(getAccessToken)
  const { isAuthenticated } = useAuth0()
  const currentBusinessId = useSelector(getCurrentBusinessId)
  const hasClosedSplashScreen = useSelector(getHasClosedClientsSplashScreen)
  const shouldShowBrowserNotice = useSelector(getShouldShowBrowserNotice)
  const errors = useSelector(getErrorsList)

  const [openAlert] = useDialog(DialogNames.DISMISSIBLE_ALERT)

  const logout = useLogout()

  const browserName = getBrowserName() as keyof typeof recommendedBrowsers

  const { benchmarkingOnlyAccess, hasBenchmarkingPlusAccess } = useBenchmarkingState()
  const analyticRoutes = useGetAnalyticsRoutes()

  const rootPathRedirect = benchmarkingOnlyAccess ?
    hasBenchmarkingPlusAccess ? BENCHMARK_PLUS_PATH : BENCHMARK_PATH
    : '/dashboard'
  // TODO: generic
  useEffect(() => {
    if (errors?.length && !recommendedBrowsers[browserName]) {
      dispatch(showRecommendedBrowsersDialog())
    }
  }, [errors])

  // TODO: generic
  useEffect(() => {
    if (errorType === FETCH_CURRENT_USER_FAILURE) {
      openAlert({
        cancelButtonText: 'Log out',
        message: USER_DEACTIVATED_MESSAGE,
        onCancel: () => logout('/auth/login'),
        onClose: () => logout('/auth/login'),
      })
    }
  }, [errorType])

  // TODO: generic
  useRedirectTo(
    '/auth/login',
    pathname,
    Boolean(pathname) && !accessToken && !isAuthenticated,
  )

  // TODO: generic
  if (!accessToken && !auth0Enabled) {
    return <Navigate replace to="/auth/login" />
  }

  return (
    <>
      <CurrentUserFetcher>
        <AnalyticsNavigatorWrapper />
        <ForceLogOutWatcher />
        <AlertsPool />
        <BusinessWatcher />
        <main className={classes.content}>
          <div className={classes.toolbar} />
          <IntercomWidget />
          <ErrorsBoundary>
            <Progress />
            {currentBusinessId && [GENERIC_ROUTES, analyticRoutes].map((routeArray, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <Routes key={`${currentBusinessId}-${index}`}>
                <Route element={<Navigate replace to={rootPathRedirect} />} path="/" />
                {routeArray.map(({ path, ...rest }, pathIndex) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <React.Fragment key={pathIndex}>
                    {renderMultiPathRoute({ path, element: <ProtectedRoute {...rest} /> })}
                  </React.Fragment>
                ))}
              </Routes>
            ))}
          </ErrorsBoundary>
        </main>
      </CurrentUserFetcher>
      {!recommendedBrowsers[browserName] && hasClosedSplashScreen && shouldShowBrowserNotice && (
        <SwitchBrowserSplashScreen />
      )}
    </>
  )
}

export default auth0Enabled
  ? withAuthenticationRequired(AnalyticsPortal, {
    // eslint-disable-next-line react/no-multi-comp
    onRedirecting: () => <CircularProgressOverlay open />,
  })
  : AnalyticsPortal
