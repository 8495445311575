import React, { forwardRef, useState } from 'react'
import { useSelector } from 'react-redux'
import classNames from 'classnames'
import { AppBar, Grid, Hidden, IconButton, Slide, Toolbar } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import MenuIcon from '@mui/icons-material/Menu'

import { getCurrentUser } from '@pbt/pbt-portal-ui/src/store/reducers/auth'
import Avatar from '@pbt/pbt-portal-ui/src/components/common/Avatar'
import BusinessLocationSwitch from '@pbt/pbt-portal-ui/src/components/dashboard/header/BusinessLocationSwitch'
import ProfileMenu from '@pbt/pbt-portal-ui/src/components/dashboard/profile/ProfileMenu'
import RecentListButton from '@pbt/pbt-portal-ui/src/components/dashboard/recent-list/RecentListButton'

import { isBenchmarking } from '@pbt/pbt-portal-ui/src/utils'
import { BenchmarkingPlusLogo } from '../../common/BenchmarkingPlusLogo'
import PetabyteAnalyticsLogo from '../../common/PetabyteAnalyticsLogo'

const useStyles = makeStyles(theme => ({
  appBar: {
    zIndex: theme.zIndex.headerTopBar,
    boxShadow: '0 1px 3px 0 rgba(229, 229, 229, 1)',
    [theme.breakpoints.up('md')]: {
      left: 0,
      top: 0,
      position: 'fixed',
    },
  },
  toolbar: theme.mixins.toolbar,
  toolbarGutters: {
    [theme.breakpoints.down('md')]: {
      paddingLeft: 0,
      paddingRight: 0,
    },
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  searchBarContainer: {
    zIndex: 0,
    margin: theme.spacing(0, 4),
  },
  searchBar: {
    width: `calc(100% - ${theme.constants.locationPickerWidth}px)`,
    minWidth: 570,
  },
  searchContainerMobile: {
    padding: theme.spacing(0, 1.5, 2),
  },
  iconButtonContainer: {
    paddingLeft: theme.spacing(2),
  },
  userAvatar: {
    zIndex: 1,
    cursor: 'pointer',
  },
  '@keyframes fadeHide': {
    '0%': { opacity: 1 },
    '100%': { opacity: 0 },
  },
  profileMenuClosed: {
    animation: '0.25s ease-out 0.1s forwards $fadeHide',
  },
}), { name: 'AnalyticsHeader' })

interface AnalyticsHeaderProps {
  onSandwichClicked: () => void
}

const AnalyticsHeader = forwardRef<HTMLDivElement, AnalyticsHeaderProps>(function AnalyticsHeader({
  onSandwichClicked,
}, ref) {
  const classes = useStyles()

  const currentUser = useSelector(getCurrentUser) || {}
  const [profileMenuOpen, setProfileMenuOpen] = useState(false)
  const avatarRef = React.createRef<HTMLSpanElement>()

  return (
    <AppBar
      className={classes.appBar}
      color="inherit"
      position="relative"
      ref={ref}
    >
      <Toolbar classes={{ regular: classes.toolbar, gutters: classes.toolbarGutters }}>
        <Grid container alignItems="center">
          <Hidden mdUp>
            <Grid item xs>
              <IconButton aria-label="Open menu" size="large" onClick={onSandwichClicked}>
                <MenuIcon />
              </IconButton>
            </Grid>
          </Hidden>
          <Grid item>
            {isBenchmarking ? <BenchmarkingPlusLogo /> : <PetabyteAnalyticsLogo />}
          </Grid>
          <Grid item xs />
          <Hidden mdDown>
            {currentUser.businessToRoleList && currentUser.businessToRoleList.length > 1 && (
              <BusinessLocationSwitch />
            )}
            <Grid item className={classes.iconButtonContainer}>
              <RecentListButton />
            </Grid>
            <Grid item className={classes.iconButtonContainer}>
              <Avatar
                plain
                className={classes.userAvatar}
                person={currentUser}
                ref={avatarRef}
                onClick={() => {
                  setProfileMenuOpen(!profileMenuOpen)
                }}
              />
            </Grid>
            <Slide direction="left" in={profileMenuOpen}>
              <ProfileMenu
                className={classNames(!profileMenuOpen && classes.profileMenuClosed)}
                onClose={e => {
                  if (avatarRef.current && !avatarRef.current.contains(e.target)) {
                    setProfileMenuOpen(false)
                  }
                }}
              />
            </Slide>
          </Hidden>
        </Grid>
      </Toolbar>
    </AppBar>
  )
})

export default AnalyticsHeader
