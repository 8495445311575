import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import classNames from 'classnames'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { useNavigate } from 'react-router-dom'
import { Text } from '@pbt/pbt-ui-components'
import {
  fetchBenchmarkingPlusValues,
  getBenchmarkingScores,
} from '@pbt/pbt-portal-ui/src/store/duck/benchmarkingPlus'
import AnalyticsPage from '@pbt/pbt-portal-ui/src/components/dashboard/analytics/AnalyticsPage'
import SpotCard from './SpotCard'
import Spot from './Spot'
import { useAnalyticsTranslations } from '../../utils/useAnalyticsTranslations'

const TOP_ROW_HEIGHT = 255

const useStyles = makeStyles(theme => ({
  container: {
    justifyContent: 'space-around',
    alignItems: 'center',
    width: 'auto',
  },
  cardsContainer: {
    zIndex: 10,
    marginTop: theme.spacing(4),
  },
  elementBox: {
    '&::after': {
      content: '" "',
      zIndex: 7,
      position: 'absolute',
      width: 'inherit',
      borderRadius: 2,
      border: theme.constants.tableBorder,
      top: 0,
      height: TOP_ROW_HEIGHT,
    },
    position: 'relative',
    backgroundColor: theme.colors.tableBackground,
  },
  cardMarginRight: {
    marginRight: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  title: {
    color: theme.colors.secondaryText,
  },
  cardsRow: {
    justifyContent: 'center',
    marginTop: theme.spacing(2),
  },
}), { name: 'SPOTLight' })

export interface ScoreCircleProps {
  score: number | null
  title: string
}

interface SpotScores {
  OPPORTUNITIES_SCORE: number | null
  OVERALL_SCORE: number | null
  PRODUCER_SCORE: number | null
  SALES_SCORE: number | null
  TRENDS_IN_MEDICINE_SCORE: number | null
}

const OVERALL_SCORE = 'OVERALL_SCORE'
const SALES_SCORE = 'SALES_SCORE'
const PRODUCER_SCORE = 'PRODUCER_SCORE'
const OPPORTUNITIES_SCORE = 'OPPORTUNITIES_SCORE'
const TRENDS_IN_MEDICINE_SCORE = 'TRENDS_IN_MEDICINE_SCORE'

const SCORE_NAME_TO_ENUM = {
  'Overall Score': OVERALL_SCORE,
  'Sales Score': SALES_SCORE,
  'Producer Score': PRODUCER_SCORE,
  'Opportunities Score': OPPORTUNITIES_SCORE,
  'Trends in Medicine': TRENDS_IN_MEDICINE_SCORE,

}
const INITIAL_SCORES: SpotScores = {
  OVERALL_SCORE: null,
  PRODUCER_SCORE: null,
  SALES_SCORE: null,
  OPPORTUNITIES_SCORE: null,
  TRENDS_IN_MEDICINE_SCORE: null,
}

const BenchmarkingPlus = () => {
  const classes = useStyles()
  const { t } = useAnalyticsTranslations('Benchmark')
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [spotScores, setSpotScores] = useState(INITIAL_SCORES)
  const scores = useSelector(getBenchmarkingScores)
  useEffect(() => {
    dispatch(fetchBenchmarkingPlusValues())
  }, [])
  useEffect(() => {
    if (scores.length > 0) {
      const parsedScores: SpotScores = { ...INITIAL_SCORES }
      scores.forEach(s => {
        if (s.scoreType in SCORE_NAME_TO_ENUM) {
          // @ts-ignore
          parsedScores[SCORE_NAME_TO_ENUM[s.scoreType]] = s.score
        }
      })
      setSpotScores(parsedScores)
    }
  }, [scores])
  return (
    <Grid container className={classes.container} direction="column" mx={3}>
      <Grid container direction="row" justifyContent="space-around" width="auto">
        <Grid height={TOP_ROW_HEIGHT} mt={2}>
          <Grid container alignItems="end" className={classes.elementBox} height="100%" mr={2} width={426}>
            <Spot benchmarkingPlus avgScore={50} backgroundColor="white" myScore={spotScores.OVERALL_SCORE} />
          </Grid>
        </Grid>
        <Grid height={TOP_ROW_HEIGHT} mt={2} zIndex={7}>
          <Grid item className={classes.elementBox} height="100%" width={789}>
            <Grid height="inherit">
              <AnalyticsPage dashboard={289} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid container className={classNames(classes.container, classes.cardsContainer)} direction="column">
        <Text className={classes.title} variant="hero">
          {t('Benchmark:SPOTLIGHTS')}
        </Text>
        <Text className={classes.title} variant="subheading">
          {t('Benchmark:SPOTLGHTS_TAP_PLUS')}
        </Text>
        <Grid container className={classes.cardsRow} direction="row">
          <Grid item className={classes.cardMarginRight}>
            <SpotCard
              reports={[
                t('Benchmark:SALES_REVENUE_GROWTH'),
                t('Benchmark:TRANSACTION_GROWTH'),
                t('Benchmark:ACT_GROWTH'),
                t('Benchmark:ACT_DEVIATION')]}
              title={t('Benchmark:SALES_REVENUE')}
              titleDescription={t('Benchmark:SALES_REVENUE_DESCRIPTION')}
              userScore={spotScores.SALES_SCORE}
              onClick={() => { navigate('/reports/sales-revenue-plus') }}
            />
          </Grid>
          <Grid item className={classes.cardMarginRight}>
            <SpotCard
              reports={[
                t('Benchmark:PRODUCER_SALES_REVENUE_GROWTH'),
                t('Benchmark:PRODUCER_TRANSACTION_GROWTH'),
                t('Benchmark:PRODUCER_ACT_GROWTH'),
                t('Benchmark:PRODUCER_ACT_DEVIATION'),
              ]}
              title={t('Benchmark:PRODUCER_PERFORMANCE')}
              titleDescription={t('Benchmark:PRODUCER_PERFORMANCE_DESCRIPTION')}
              userScore={spotScores.PRODUCER_SCORE}
              onClick={() => { navigate('/reports/producer-performance-plus') }}
            />
          </Grid>
          <Grid item className={classes.cardMarginRight}>
            <SpotCard
              reports={[
                t('Benchmark:NEW_CLIENT_TRANSACTIONS'),
                t('Benchmark:PATIENT_LAPSING_RATE'),
                t('Benchmark:CLIENT_BONDING_RATE'),
                t('Benchmark:FORWARD_BOOKING_RATE'),
              ]}
              title={t('Benchmark:OPPORTUNITIES')}
              titleDescription={t('Benchmark:OPPORTUNITIES_DESCRIPTION')}
              userScore={spotScores.OPPORTUNITIES_SCORE}
              onClick={() => { navigate('/reports/opportunities-plus') }}
            />
          </Grid>
          <Grid item>
            <SpotCard
              reports={[
                t('Benchmark:PATIENT_VISIT_TRANSACTIONS'),
                t('Benchmark:AVERAGE_ANNUAL_PATIENT_VISIT_COUNT'),
                t('Benchmark:WELL_EXAM_APPOINTMENT_TYPE'),
                t('Benchmark:PROGRESS_EXAM_APPOINTMENT_TYPE'),
              ]}
              title={t('Benchmark:TRENDS_IN_MEDICINE')}
              titleDescription={t('Benchmark:TRENDS_IN_MEDICINE_DESCRIPTION')}
              userScore={spotScores.TRENDS_IN_MEDICINE_SCORE}
              onClick={() => { navigate('/reports/trends-in-medicine-plus') }}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default BenchmarkingPlus
