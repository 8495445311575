import { PermissionArea } from '@pbt/pbt-ui-components'
import { v4 as uuid } from 'uuid'

export const ReportingRouteConfig = {
  CANCELLATIONS_AND_NO_SHOWS: {
    id: uuid(),
    text: 'Cancellations & no shows',
    hideIfNoPermissions: true,
    permissionArea: PermissionArea.BENCHMARKING_PLUS,
    url: '/reports/cancellations-and-no-shows',
  },
  CLIENT_AND_PATIENT_STATUS: {
    id: uuid(),
    text: 'Client and patient status',
    hideIfNoPermissions: true,
    permissionArea: PermissionArea.ANALYTICS_REPORTS,
    url: '/reports/client-and-patient-status',
  },
  CLIENT_LIST: {
    id: uuid(),
    text: 'Client list',
    hideIfNoPermissions: true,
    permissionArea: PermissionArea.MARKETING_REPORTS,
    url: '/reports/client-list',
  },
  CONTROLLED_SUBSTANCE_LOG: {
    id: uuid(),
    text: 'Controlled substance log',
    hideIfNoPermissions: true,
    permissionArea: PermissionArea.PIMS,
    url: '/reports/controlled-substance-log',
  },
  ACQUISITION_AND_LIFETIME_VALUE: {
    id: uuid(),
    text: 'Acquisition and lifetime value',
    hideIfNoPermissions: true,
    permissionArea: PermissionArea.ANALYTICS_REPORTS,
    url: '/reports/customer-acquisition-and-lifetime-value',
  },
  DAILY_RECONCILIATION: {
    id: uuid(),
    text: 'Daily reconciliation',
    hideIfNoPermissions: true,
    permissionArea: PermissionArea.ANALYTICS_REPORTS,
    url: '/reports/daily-reconciliation',
  },
  FORWARD_BOOKINGS: {
    id: uuid(),
    text: 'Forward bookings',
    hideIfNoPermissions: true,
    permissionArea: PermissionArea.ANALYTICS_REPORTS,
    url: '/reports/forward-bookings',
  },
  GROUP_PERFORMANCE: {
    id: uuid(),
    text: 'Group performance',
    hideIfNoPermissions: true,
    permissionArea: PermissionArea.GROUP_REPORTS,
    url: '/reports/group-performance',
  },
  GROUP_PRICE_LISTS: {
    id: uuid(),
    text: 'Group price lists',
    hideIfNoPermissions: true,
    permissionArea: PermissionArea.GROUP_REPORTS,
    url: '/reports/group-price-lists',
  },
  INVENTORY_USAGE: {
    id: uuid(),
    text: 'Inventory Usage',
    hideIfNoPermissions: true,
    permissionArea: PermissionArea.ANALYTICS_REPORTS,
    url: '/reports/inventory-usage',
  },
  MAGIC_QUADRANT: {
    id: uuid(),
    text: 'Magic quadrant',
    hideIfNoPermissions: true,
    permissionArea: PermissionArea.ANALYTICS_REPORTS,
    url: '/reports/magic-quadrant',
  },
  MARKETING_DATA_QUALITY: {
    id: uuid(),
    text: 'Marketing data quality',
    hideIfNoPermissions: true,
    permissionArea: PermissionArea.MARKETING_REPORTS,
    url: '/reports/marketing-data-quality',
  },
  MONTHLY_SALES_SUMMARY: {
    id: uuid(),
    text: 'Monthly sales summary',
    hideIfNoPermissions: true,
    permissionArea: PermissionArea.ANALYTICS_REPORTS,
    url: '/reports/monthly-sales-summary',
  },
  OPEN_SOAPS_AND_INVOICES: {
    id: uuid(),
    text: 'Open SOAPs and Invoices',
    hideIfNoPermissions: true,
    permissionArea: PermissionArea.ANALYTICS_REPORTS,
    url: '/reports/open-soaps-and-invoices',
  },
  PRACTICE_PERFORMANCE: {
    id: uuid(),
    text: 'Practice performance',
    hideIfNoPermissions: true,
    permissionArea: PermissionArea.ANALYTICS_REPORTS,
    url: '/reports/practice-performance',
  },
  PRODUCTION: {
    id: uuid(),
    text: 'Production',
    hideIfNoPermissions: true,
    permissionArea: PermissionArea.ANALYTICS_REPORTS,
    url: '/reports/production',
  },
  RABIES_VACCINE_HISTORY: {
    id: uuid(),
    text: 'Rabies vaccine history',
    hideIfNoPermissions: true,
    permissionArea: PermissionArea.PIMS,
    url: '/reports/rabies-vaccine-history',
  },
  REMINDERS_COMPLIANCE: {
    id: uuid(),
    text: 'Reminders compliance',
    hideIfNoPermissions: true,
    permissionArea: PermissionArea.ANALYTICS_REPORTS,
    url: '/reports/reminders-compliance',
  },
  REFERRAL_BUSINESS: {
    id: uuid(),
    text: 'Referral Business',
    hideIfNoPermissions: true,
    permissionArea: PermissionArea.ANALYTICS_REPORTS,
    url: '/reports/referral-business',
  },
  MEDICAL_INSIGHTS: {
    id: uuid(),
    text: 'Medical insights',
    hideIfNoPermissions: true,
    permissionArea: PermissionArea.PIMS,
    url: '/reports/medical-insights',
  },
  SCHEDULE_MANAGEMENT: {
    id: uuid(),
    text: 'Schedule management',
    hideIfNoPermissions: true,
    permissionArea: PermissionArea.ANALYTICS_REPORTS,
    url: '/reports/schedule-management',
  },
  STAFF_TIME_TRACKING: {
    id: uuid(),
    text: 'Staff time tracking',
    hideIfNoPermissions: true,
    permissionArea: PermissionArea.PIMS,
    url: '/reports/time-tracking',
  },
  WELLNESS_PLANS: {
    id: uuid(),
    text: 'Wellness plans',
    hideIfNoPermissions: true,
    permissionArea: PermissionArea.PIMS,
    url: '/reports/wellness-plans',
  },
}
