import React from 'react'
import { useSelector } from 'react-redux'
import { Grid, IconButton } from '@mui/material'

import makeStyles from '@mui/styles/makeStyles'
import { ClassesType, Text } from '@pbt/pbt-ui-components'
import { Delete as DeleteIcon } from '@pbt/pbt-ui-components/src/icons'

import ClinicLogo from '@pbt/pbt-portal-ui/src/components/common/logos/ClinicLogo'
import { getBusiness } from '@pbt/pbt-portal-ui/src/store/reducers/businesses'

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.colors.panelRow,
  },
  practiceLogo: {
    backgroundColor: theme.colors.tableBackground,
    width: 100,
    height: 56,
    boxShadow: '1px 1px 2px 0 rgba(60,56,56,0.14), 0 1px 4px 0 rgba(60,56,56,0.12)',
  },

}), { name: 'PracticeDetailsChildRow' })

export interface PracticeDetailsChildRowProps {
  businessId: string
  classes: ClassesType<typeof useStyles>
  onDelete: () => void
}

const PracticeDetailsChildRow = ({ businessId, classes: classesProp, onDelete }: PracticeDetailsChildRowProps) => {
  const classes = useStyles({ classes: classesProp })
  const business = useSelector(getBusiness(businessId))

  return (
    <Grid container alignItems="center" className={classes.root} p={1}>
      <Grid item>
        {business && (
          <ClinicLogo
            className={classes.practiceLogo}
            clinic={business}
          />
        )}
      </Grid>
      <Grid item md pl={5}>
        <Text variant="h3">{business?.name}</Text>
      </Grid>
      <Grid item>
        <IconButton size="large" sx={{ p: 0.5 }} onClick={onDelete}>
          <DeleteIcon />
        </IconButton>
      </Grid>
    </Grid>
  )
}

export default PracticeDetailsChildRow
